<template>
  <div>
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        @submit="handleQuery"
        @reset="handleQuery"
      >
        <template #shipperId>
          <nh-shipper-selector
            v-model:shipperId="searchQuery.shipperId"
          />
        </template>
      </PlForm>
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :columns="TABLECOLUMN"
        show-summary
        :show-table-setting="true"
        :summary-method="handleGetSummaries"
        @selection-change="handleSelectionChange"
      >
        <template #tableHeaderRight>
          <nh-button
            type="grey"
            :track="{
              trackName: '入库管理/上架明细/[导出]'
            }"
            @click="handleExport"
          >
            导出
          </nh-button>
        </template>
      </PlTable>
    </pl-block>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import tableSummary from '@/utils/tableSummary';
import moment from '@/utils/moment';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import summaryConfig from './config';

import { listingPage, listingExport } from './api';

export default {
  name: 'ListingDetail',
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      searchQuery: {
        shipperId: null,
        listingTime: [moment.startOf(new Date(), 'month'), moment.ensOf(new Date(), 'month')],
      },
      subTaskRecordIds: [],
      loading: {
        getTableData: false,
        handleShippeKeywordrChange: false,
      },
    };
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleGetSummaries({ columns, data }) {
      const config = summaryConfig;
      const sums = [];
      columns.forEach((column, index) => {
        sums[index] = tableSummary({ column, data, config });
      });
      return sums;
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      this.tableData = [];
      const [fromCreateTime, toCreateTime] = this.searchQuery.listingTime || [];
      const params = {
        ...this.searchQuery,
        fromCreateTime,
        toCreateTime,
      };
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await listingPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.subTaskRecordIds = selection.map((item) => item.id);
    },
    async handleExport() {
      const [fromCreateTime, toCreateTime] = this.searchQuery.listingTime || [];
      const data = { ...this.searchQuery, fromCreateTime, toCreateTime };
      if (this.subTaskRecordIds.length > 0) {
        data.subTaskRecordIds = this.subTaskRecordIds;
      }
      listingExport(data);
    },
  },
};
</script>
