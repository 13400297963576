import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:上架明细分页
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14404
 */
export function listingPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/receipt_order/upper_page', data, {
    params,
  });
}

/**
 * @description:上架明细导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/14406
 */
export function listingExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/receipt_order/upper_export', data, { fileName: '上架明细', extension: 'xlsx' });
}
